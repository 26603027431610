<template>
  <div class="max-w-md mx-auto">
    <div class="bg-white shadow-md px-4 pt-6 pb-24 relative">
      <div class="flex flex-col items-center">
        <img
          :src="
            project.current.logo_url
              ? project.current.logo_url
              : `${publicPath}img/logo-placeholder.png`
          "
          :alt="project.current.name"
          class="h-20 w-20 border"
        />
        <h2 class="text-3xl font-bold text-center mt-4">{{ project.current.name }}</h2>
      </div>

      <div class="absolute top-6 right-4 space-x-2">
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring h-10 w-10"
          @click="showDeleteConfirmation = true"
        >
          <i class="tmlps-i-delete-circle text-3xl h-8 w-8"></i>
        </button>

        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring h-10 w-10"
          @click="showEditModal = true"
        >
          <i class="tmlps-i-edit-circle text-3xl h-8 w-8"></i>
        </button>
      </div>

      <div class="mt-5">
        <p class="text-base font-bold text-center">Información de la cuenta</p>

        <div class="py-4 border-b flex items-center justify-between">
          <p>Estatus del proyecto</p>

          <BaseSwitch
            :value="project.current.is_active"
            @input="handleSwitchInput"
            @on="handleSwitchOn"
            @off="handleSwitchOff"
          />
        </div>

        <div class="py-4 border-b flex items-center justify-between">
          <p>Dominio</p>
          <div>{{ project.current.domain ? project.current.domain.name : null }}</div>
        </div>
      </div>

      <div class="mt-0">
        <div class="py-4 border-b flex items-center justify-between">
          <p>Color principal</p>
          <div>
            <span
              class="block w-7 h-7 bg-gray-800"
              :style="`background-color: ${project.current.theme_color}`"
            ></span>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <div class="py-4 border-b flex items-center justify-between">
          <p>Espacio de la cuenta</p>
          <div>{{ project.current.video_storage_limit_kb | kb_to_gb }} GB</div>
        </div>

        <div class="py-4 flex border-b items-center justify-between">
          <p>Espacio utilizado</p>
          <div>{{ project.current.storage_space_used_kb | kb_to_gb }} GB</div>
        </div>
      </div>

      <div class="mt-1 grid grid-cols-2 border-b">
        <div class="py-4 items-center justify-between">
          <p>Latitud</p>
          <div>{{ project.current.latitude }}</div>
        </div>

        <div class="py-4 items-center justify-between">
          <p>Longitud</p>
          <div>{{ project.current.longitude }}</div>
        </div>
      </div>

      <div class="mt-1">
        <div class="py-4 border-b flex items-center justify-between">
          <p>Zona horaria</p>
          <div>{{ project.current.timezone }}</div>
        </div>
      </div>
    </div>

    <project-edit-modal :show="showEditModal" @close="showEditModal = false" />

    <project-disable-confirmation-modal
      :show="showDisableConfirmation"
      :project="project.current"
      @close="showDisableConfirmation = false"
      @cancel="handleCancelDisable"
    />

    <project-delete-confirmation-modal
      :show="showDeleteConfirmation"
      :project="project.current"
      @close="showDeleteConfirmation = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSwitch from '@/components/BaseSwitch';
import ProjectEditModal from './components/ProjectEditModal';
import ProjectDeleteConfirmationModal from './components/DeleteConfirmationModal';
import ProjectDisableConfirmationModal from './components/DisableConfirmationModal';
export default {
  name: 'project-information',
  components: {
    BaseSwitch,
    ProjectEditModal,
    ProjectDeleteConfirmationModal,
    ProjectDisableConfirmationModal,
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
    ...mapState(['project']),
  },
  data() {
    return {
      showDisableConfirmation: false,
      showEditModal: false,
      showDeleteConfirmation: false,
    };
  },
  methods: {
    async handleSwitchOn() {
      await this.$store.dispatch('project/enableProject', this.project.current.id);
    },
    handleSwitchOff() {
      this.showDisableConfirmation = true;
    },
    handleSwitchInput(value) {
      if (value) {
        this.$store.commit('project/ENABLE_PROJECT');
      } else {
        this.$store.commit('project/DISABLE_PROJECT');
      }
    },
    handleCancelDisable() {
      this.$store.commit('project/ENABLE_PROJECT');
    },
  },
};
</script>
